<!-- 设置密码 -->
<template>
  <div id="MoneyPwd">
    <van-nav-bar :title="$t('m_securityCenter.sztxmm')" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <van-form @submit="onSubmit">
      <van-field
        v-if="type == 'edit'"
        v-model="password"
        name="password"
        :label="$t('m_changeWithdrawPassword.jmm')"
        type="password"
        :placeholder="$t('m_modifyLoginPassword.qsrjmm')"
        maxlength="4"
        :rules="[{ required: true, validator, message: $t('m_changeWithdrawPassword.qsrsz') }]"
      />
      <van-field
        v-model="form.password"
        name="password"
        :label="$t('m_changeWithdrawPassword.xmm')"
        type="password"
        :placeholder="$t('m_changeWithdrawPassword.qsrsz')"
        maxlength="4"
        :rules="[{ required: true, validator, message: $t('m_changeWithdrawPassword.qsrsz') }]"
      />
      <van-field
        v-model="form.pay_password"
        type="password"
        name="newPass"
        maxlength="4"
        :label="$t('m_changeWithdrawPassword.qrxmm')"
        :placeholder="$t('m_changeWithdrawPassword.qsrsz')"
        :rules="[{ required: true, validator, message: $t('m_changeWithdrawPassword.qsrsz') }]"
      />
      <div class="forgetPwd toService" v-if="type == 'edit'">
        {{$t('m_changeWithdrawPassword.zhwyzfswjjmm')}}
        <router-link to="/cusService">{{$t('m_changeWithdrawPassword.lxkf')}}</router-link>
      </div>
      <div style="margin: 16px">
        <van-button block class="btn" type="info" native-type="submit"
          >{{$t('m_changeWithdrawPassword.qr')}}</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { editPayPassword, payPassword } from "@/api/user";
import { Toast } from "vant";
export default {
  name: "",
  data() {
    return {
      type: "add",
      username: "",
      password: "", // 旧密码
      form: {
        password: "",
        pay_password: "",
      },
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    let query = this.$route.query;
    if (query.type) {
      this.type = "edit";
    }
  },
  methods: {
    // 校验函数返回 true 表示校验通过，false 表示不通过
    validator(val) {
      var reg = /^\d+$/;
      return reg.test(val);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    loadingCom() {
      // 自定义加载图标
      const toast = Toast.loading({
        message: this.$t('common.loading'),
        forbidClick: true,
        loadingType: "spinner",
        duration: 60000,
      });
    },
    onSubmit() {
      console.log("点击了提交");
      if (this.form.password + "" !== this.form.pay_password + "") {
        Toast.fail(this.$t('m_password.byz'));
        return;
      }
      if (this.type == "add") {
        this.editPayPassword();
      } else if (this.type == "edit") {
        console.log("===========");
        this.payPasswordFun();
      }
    },
    /* 设置提现密码 */
    editPayPassword() {
      this.loadingCom();
      editPayPassword(this.form).then((res) => {
        console.log(res);
        Toast.clear();
        Toast.success(this.$t('m_cue.czcg'));
        setTimeout(() => {
          this.onClickLeft();
        }, 1000);
      });
    },
    /* 修改提现密码 */
    payPasswordFun() {
      this.loadingCom();
      let dataObj = {
        pay_password: this.password,
        new_pay_password: this.form.password,
        renew_pay_password: this.form.pay_password,
      };
      payPassword(dataObj).then((res) => {
        console.log(res);
        Toast.clear();
        if (res.code + "" === "201") {
          Toast(this.$t('m_password.mmcw'));
        } else {
          Toast.success(this.$t('m_cue.czcg'));
          setTimeout(() => {
            this.onClickLeft();
          }, 1000);
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.van-nav-bar {
  background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important;
}

/deep/.van-nav-bar__title {
  color: #38373b !important;
}
/deep/.van-nav-bar .van-icon {
  color: #38373b !important;
}

/deep/.van-field__label {
  color: #ceab77;
}

.van-cell {
  padding: 15px 16px;
}

.van-cell::after {
  border-color: #404040;
}

.btn {
  background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);
  font-size: 0.34rem;
  border: none;
}

.forgetPwd {
  font-size: 0.24rem;
  line-height: 0.6rem;
  text-align: right;
  color: #fff;
  padding: 0 0.2rem;
  box-sizing: border-box;
}
</style>